import React, { useState } from 'react'
// Core modules imports are same as usual
import { Navigation, Pagination, FreeMode } from 'swiper';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/free-mode/free-mode.scss'; // Free mode module
import '../../../slider/styles.css';

const HeroFilter = ({ config, filters, runFilter } ) => {

	return (
		<div id="hero-filter">
			<h3 className="text-center">{config.title}</h3>
			<div className='hero-filter'>
				<Swiper
					slidesPerView={3}
					spaceBetween={0}
					navigation={true}
					breakpoints={{
					"@0.00": {
						slidesPerView: 3,
						spaceBetween: 0,
					},
					"@0.50": {
						slidesPerView: 3,
						spaceBetween: 10,
					},
					"@1.00": {
						slidesPerView: 4,
						spaceBetween: 10,
					},
					"@1.50": {
						slidesPerView: 6,
						spaceBetween: 10,
					},
					"@2.00": {
						slidesPerView: 8,
						spaceBetween: 10,
					},
					"@2.50": {
						slidesPerView: 10,
						spaceBetween: 10,
					},
					}}
					modules={[FreeMode, Pagination, Navigation]}
					className="mySwiper"
				>
					<SwiperSlide>
					
						<Tag 
							tag={{
								id: 0,
								slug: 'all-activities',
								name: 'All activities'
							}} 
							selected={filters.tag == 0} 
							runFilter={runFilter} 
						/>
						
					</SwiperSlide>
					{config.tags.map( tag => (
						<SwiperSlide key={`tag-${tag.id}`}>
							<Tag 
								tag={tag} 
								selected={filters.tag == tag.id} 
								runFilter={runFilter} 
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	)
}

const Tag = ({ tag, selected, runFilter }) => {
	
	const styleObj = selected ? 'filter highlight' : 'filter'

	return (
		
		<div className={`tag-filter ${tag.slug}`}>
			<div className={styleObj}>
				<a onClick={() => runFilter( { key: 'tag', value: tag.id } )} >
					<div className="icon-holder light-grey large">
						<span className={`icon large icon-${tag.slug}`}></span>
					</div>
					<p className="filter-name text-center">{tag.name}</p>
				</a>
			</div>
		</div>
		
	)
}

export default HeroFilter
