import React, { useState } from "react"

const Products = () => {

	return (
		<div>
			PRODUCTS SECTION
		</div>
	)
}



export default Products
