import React, {useEffect} from "react";

const ErrorNotification = ({ error, clearError }) => {


	console.log( error );

	useEffect( () => {
		if( error ) {
			console.log('call')
			try {

				window.scroll({
					top:0,
					left:0,
					behaviour: "smooth"
				});

			} catch(err){
				window.scrollTo(0,0)
			}
		}
	},[error])

	return (
		<div>
			{error ? (
				<div className="profile-error-message">
					<h4>{error.title}</h4>
					{error.detail != null && <p dangerouslySetInnerHTML={{ __html: error.detail}} /> }
					<a className="remove-error" onClick={()=>clearError()}><span className="icon icon-cross"></span></a>
				</div>
			) : null}
		</div>
	)
};

export default ErrorNotification;
