import React, {useState} from 'react';
import axios from 'axios'
import ProcessButton from 'shared/process-button'

const Signup = ({ token, fields, setView, optoutMarketing, setOptoutMarketing, facebookLogin, allowLogin, success }) => {

	const [ formData, setFormData ] = useState({
		email: '',
		password: '',
		name: '',
        mobile_number: ''
	})

	const [ submitted, setSubmitted ] = useState( false )
	const [ error, setError ] = useState( false )
    const [ resetPassword, setResetPassword ] = useState( false )


	const isButtonActive = () => {
		return formData.email.length > 0 && formData.password.length > 0 && formData.name.length > 0
	}

    const handleSubmit = (event) =>{

		event.preventDefault();

        setError( false )
	    setSubmitted( true )
        setResetPassword( false )


        const postData = {
            user: formData,
            authenticity_token: token,
            optout_marketing: optoutMarketing,
            short_name: 1,
            fields: fields
        };

        axios.post( '/signup.json', postData ).then(response => {
            success( response.data.user );
        }).catch(err => {

            let errors = {};

            if( err.response.status == 500 ) {
                errors.general = <ErrorMessage key="errors-general" message={err.response.statusText} />
            } else {
                Object.keys(err.response.data.errors).map((key, index) => {
                    errors[key] = <ErrorMessage key={`error-${index}`} message={err.response.data.errors[key]} />
                });
            }

            if( err.response.data.resetPassword ) {
                errors.email = <ResetMessage key="errors-reset" setView={setView} />
            }

            setSubmitted( false)
            setError( errors )
            setResetPassword( err.response.data.resetPassword ? true : false )
        });
	}

    const hasError = ( key, errorClass=false ) => {
		let messages = [];
		let errorClassList = [ errorClass ];

		if( error  && error.hasOwnProperty( key ) ) {
			if( errorClass ) {
				errorClassList.push( 'animated shakeme input_error');
			} else {
				messages.push( error[key] );
			}
		} else {
			return errorClass ? errorClassList.join(' ') : null;
		}

		if( errorClass ) {
			return errorClassList.join(' ');
		} else {
			return messages;
		}
	}

	return (

		<form onSubmit={handleSubmit} action="/signup" className="signup-form" autoComplete="on" acceptCharset="UTF-8" method="post">

            {error && error.general != undefined
                ? <div className="callout danger">
                    {error.general}
                </div>
                : null
            }

			<h4>Your details:</h4>

			<label className="form-label">
				<input
	          name="email"
	          type="email"
	          value={formData.email}
	          onChange={(e) => setFormData({...formData, email: e.target.value})}
	          className={hasError('email', 'form-margin')}
	          placeholder="Your email address"
	       />
			 </label>
			{hasError('email')}

			<label className="form-label">
				<input
	        name="password"
	        type="password"
	        value={formData.password}
	        onChange={(e) => setFormData({...formData, password: e.target.value})}
	        className={hasError('password', 'form-margin')}
	        placeholder="Choose a password"
	    	/>
			</label>
			{hasError('password')}

			<label className="form-label">
	      <input
	          name="name"
	          type="text"
	          value={formData.name}
	          onChange={(e) => setFormData({...formData, name: e.target.value})}
	          placeholder="Your full name"
	          className={hasError('name', 'form-margin')}
	      />
			</label>
			{hasError('name')}

			<label className="form-label">
	      <input
	          name="mobile_number"
	          type="number"
	          value={formData.mobile_number}
	          onChange={(e) => setFormData({...formData, mobile_number: e.target.value})}
	          className={hasError('mobile_number', 'form-margin')}
						placeholder="Mobile number (optional)"
				/>
			</label>
			{hasError('mobile_number')}

			<label htmlFor="optout_marketing" className="checkbox inline tickbox-label-content">
				<input
            type="checkbox"
            id="optout_marketing"
            checked={optoutMarketing}
            name="optout_marketing"
            value="1"
            onChange={( e ) => setOptoutMarketing(e.target.checked)}
        />
				<span>Tick this box if you would not like to receive Fizzbox marketing offers and promotions via email.</span>
			</label>

			<ProcessButton
				buttonType="submit"
				buttonClass="button primary expanded"
				disabled={!isButtonActive()}
			  process={submitted}
			>
				Continue with email
			</ProcessButton>


			<div className="fb-login">
				<p className="or-divider"><span>or</span></p>
				<a className="button"
            onClick={ (e) => {
                e.preventDefault();
                facebookLogin( { auth: 1 } )
            }}>

            <b>Continue with Facebook</b>
        </a>
			</div>

			{allowLogin && <div className="text-center">Have an account? <a onClick={() => setView('login')}>Login</a></div>}

		</form>
	)
}

const ResetMessage = ({ setView }) => (
	<div className="signup-invalid-message">Sorry the email you entered is already taken, if it's yours {<a onClick={() => setView('reset')}>reset your password</a>}</div>
);

const ErrorMessage = props => (
	<div className="signup-invalid-message">{props.message}</div>
);

export default Signup
