import React, { useEffect, useState } from 'react'
import Search from '../shared/search'

import ListItem from './item'

const ListTab = ({ listData, truncate, search, multi, ident, selected, setFilter, redirect }) => {
	
	//do we need to truncate the data?
	const [ data, setData ] = useState( [] )
	const [ additional, setAdditional ] = useState( [] )
	const [ showMore, setShowMore ] = useState( false )

	useEffect(() => {
		setDefaultData()
	}, [])

	const has_truncate = truncate !== false
	const contains = str => item => str ? item.name.toLowerCase().includes(str.toLowerCase()) : true

	const setDefaultData = () => {	
		let filterItems = listData
		let additionalItems = []

		if( has_truncate ) {
			//split onto the first x records and the rest
			filterItems = listData.slice(0, truncate.limit )
			additionalItems = listData.slice(truncate.limit, listData.length )
		}

		setData( filterItems )
		setAdditional( additionalItems )
	}

	const doSearch = ( str ) => {
		if( str == null || str.length == 0 ) {
			setDefaultData()
		} else {
			const data = listData.filter( contains( str ) )
			setData( data )
			setAdditional( [] )
		}
	}

	return (
		
		<>
		{search !== false ? (
			<Search config={search} doSearch={doSearch} />
		) : null}

		<div className="checkbox-container custom">
			{data.map(( item, index ) => (
				<ListItem 
					key={`primary-${index}`} 
					ident={ident}
					multi={multi}
					item={item} 
					selected={selected}
					setFilter={setFilter}
					redirect={redirect}
				/>
			))}

			{truncate && additional.length > 0 ? (
				<>
				{!showMore ? (
				<div>
					<a href="#" className="link small underline black" onClick={(e) => {
						e.preventDefault()
						setShowMore(!showMore)
					}}>
						{truncate.linkText} ({additional.length})
					</a>
				</div>
				) : null}

				{showMore ? (
					<>
						{additional.map(( item, index ) => (
							<ListItem 
								key={`additional-${index}`} 
								ident={ident}
								multi={multi}
								item={item} 
								selected={selected}
								setFilter={setFilter}
								redirect={redirect}
							/>
						))}
					</>
				) : null}
				</>
				
			)  :null}
		</div>
		</>
	)
}


export default ListTab