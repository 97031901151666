import React, { useEffect, useState } from 'react'
import Search from './shared/search'

import ListTab from './list/tab'

const TabbedListType = ({ definition, selected, setFilter }) => {
	
    const [ activeTab, setActiveTab ] = useState( 0 )

	return (
		
        <div>
            <ul className="filter-tabs">
                {definition.config.tabs.map(( tab, index ) => (
                    <li 
                        className={ activeTab == index ? 'active' : '' }
                        key={`tab-${index}`}
                        >
                            <a onClick={() => setActiveTab( index )}>
                                {tab.label}
                            </a>
                    </li>
                ))}
            </ul>

            {definition.config.tabs.map(( tab, index ) => {
                if( activeTab == index ) {
                    return (
                        <ListTab 
                            key={`tab-${index}`} 
                            listData={definition.data[index]} 
                            truncate={tab.truncate} 
                            search={tab.search} 
                            multi={definition.config.multi} 
                            ident={definition.ident} 
                            selected={selected} 
                            setFilter={setFilter}
                            redirect={definition.config.redirect}
                        />
                    )
                }
            })}  
        </div>
		
	)
}

export default TabbedListType
