import React, { useState } from 'react';

import ReactModal from 'react-modal';

import { StateProvider } from 'shared/context/global-context'

import NoRiskImage from 'listing/home-page/no-risk.svg';
import TickImage from 'listing/svg/tick.svg';
import Button from './button';
import Form from './form';
import Account from 'shared/auth/account'


const ModalStyles = {

	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.75)'
	},
	content : {
		padding: '0',
		width: '100%',
		maxWidth: '1100px',
		height: 'auto',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}

};

ReactModal.setAppElement('body');


const Registration = ({ data }) => {

	const [ showModal, setShowModal ] = useState( false )
	const [ view, setView ] = useState( 'form' )
	const [ auth, setAuth ] = useState( data.auth ? data.auth_data : false )

	console.log( data );
	return (
		<StateProvider>
			<Button openModal={() => setShowModal( true )} />
			<ReactModal isOpen={showModal} style={ModalStyles}>

				<>
					<div className="enquiry-form-container">
						<div className="grid-x">

								{data.auth && data.supplier ? (
									<ExistingSupplier path={data.path} />
								) : (
									<>
										<LeftColumn
											auth={auth}
											view={view}
											toggleLogin={(e) => setView( view == 'auth' ? 'form' : 'auth' )}
										/>

										{view == 'form' &&
											<div className="cell medium-8 form-right-column small-order-1 large-order-2">
												<h1>List with us!</h1>
												<Form
													auth={auth}
													sections={data.sections}
													token={data.token}
													closeModal={() => setShowModal( false )}
													setView={setView}
												/>
											</div>
										}
										{view == 'auth' &&

											<div className="cell medium-8 form-right-column small-order-1 large-order-2">
												<h1>Login to Fizzbox!</h1>
												<Account
													defaultView="login"
													token={data.token}
													back={(e) => setView( 'form' )}
													success={( user ) => {

														setAuth({
															name: user.name,
															email: user.email
														})

														setView( 'form' )
													}}
												/>
											</div>
										}
										{view == 'success' &&

											<div className="cell medium-8 form-right-column small-order-1 large-order-2">
												<h1 className="green"><img src={TickImage} alt="Tick icon" /> Enquiry sent!</h1>
												<p>Thank you for your interest in listing with Fizzbox. We’ll be in touch as soon as we can, if we feel your experiences will be a good fit for our marketplace. We’re a small team, so we prioritise listing decisions based on data, trends and seasonality. Apologies in advance for any delay in getting back to you and thanks again for applying to list!</p>
												<button
													className="button primary"
													onClick={() => {
														setView('form')
														setShowModal( false )
													}}
												>
													Close
												</button>
											</div>
										}
									</>
								)}


						</div>
					</div>
					<button
						className="close-button"
						onClick={() => {
							setView('form')
							setShowModal( false )
						}}
					>
						<span className="icon icon-cross" />
					</button>
				</>

			</ReactModal>
		</StateProvider>
	)
}

const ExistingSupplier = ({path}) => {
	return (
		<div className="enquiry-form-container existing">
			<p>Thank you for being a valued Fizzbox provider</p>
			<a href={path} className="button primary">Continue to your bookings</a>
		</div>
	)
}

const LeftColumn = ({ auth, view, toggleLogin }) => {
	return (
		<div className="cell medium-4 form-left-column small-order-2 large-order-1">
			<div className="left-column-container">
				<img src={NoRiskImage} alt="no risk!" />
				<h2>You can look forward to:</h2>
				<ul>
					<li>No Fees</li>
					<li>Being in control</li>
					<li>Growing your market share</li>
					<li>No risk profits</li>
					<li>A simple &amp; easy to use system</li>
				</ul>
				{!auth && view != 'auth' && (
					<>
						<p>Already have a Fizzbox account?</p>
						<a onClick={toggleLogin}>Login in here</a>
					</>
				)}
			</div>
		</div>
	)
}

export default Registration
