import React from 'react'
const Order = ({ order, changeOrder }) => {
	return (
		
		<div className="order-changer align-content vertical center">
			<select className="popularity-dropdown full-width" id="popularity-select" name="popularity" value={order} onChange={(e) => changeOrder(e.target.value)}>
				<option value="1">Sort: Popularity</option>
				<option value="2">Price</option>
				<option value="3">Name</option>
				<option value="4">Newest</option>
			</select>
		</div>
	
	)
}
export default Order
