import React from "react"
import { StateProvider } from 'shared/context/global-context'
import { Provider } from 'use-http'
import App from './app'

const Administration = ({ user }) => {
	
	const options = {
		cacheLife: 0,
		onError: ({ error }) => {
			console.log('HANDLED ERROR', error.data );
		},
		interceptors: {
			request: ({ options, url, path, route }) => {
				clearError();
				return options;
			}
		}
	}
	
	return (
		<StateProvider user={user}>
			<Provider options={options}>
				<App />
			</Provider>
		</StateProvider>
	)
}

export default Administration
