import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal';
import axios from 'axios'
import ButtonSpinner from '../../../shared/button-spinner'

ReactModal.setAppElement('body');

const path = '/supplier/templates';


const ModalStyles = {
  overlay: {
	  backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  content : {
  	padding				        :	'0',
	  width				          : 'auto',
	  maxWidth			        : '600px',
	  height				        : 'auto',
	  maxHeight			        : '650px',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-300px',
    overflow			        : 'hidden',
    transform             : 'translate(-50%, -50%)'
  }
};


const Templates = ({config, setMessage }) => {
	
	const [ templateWindow, showTemplateWindow ] = useState( false )
	
	const setTemplateContent = ( content, e ) => {
		e.preventDefault()
		setMessage( content )
		showTemplateWindow( false )
	}
	
	return (
		<>
			<div className="saved-template"><a onClick={(e) => showTemplateWindow(true)}>Use a saved message template</a></div>
			<ReactModal isOpen={templateWindow} style={ModalStyles}>
				<button onClick={(e) => showTemplateWindow( false )} className="close-button clearfix" aria-label="Close modal"><span aria-hidden="true">×</span></button>
				<TemplateWindow config={config} setTemplateContent={setTemplateContent} />
			</ReactModal>
		</>
				
	)
}


const TemplateWindow = ({config, setTemplateContent}) =>  {

	const [ view, setView ] = useState( 'list' )
	const [ templates, setTemplates ] = useState( [] )
	const [ template, setTemplate ] = useState( null )
	
	
	useEffect(() => {
		loadTemplates()
	}, [])
	
	const loadTemplates = () => {
		const url = config.path + '/templates';
		axios.get( url ).then(response => {
			setTemplates( response.data )
			setView('list')
		}).catch(err => {
			console.log(err);
		});

	}

	const toggleView = ( view, template=null, event ) => {
		event.preventDefault();
		setView( view )
		setTemplate( template )
	}

	switch( view ) {
		case 'new':
			return ( 
				<NewTemplate 
					toggleView={toggleView} 
					token={config.token} 
					loadTemplates={loadTemplates} 
				/> 
			)
			break;
		case 'edit':
			return ( 
				<EditTemplate 
					template={template} 
					toggleView={toggleView} 
					token={config.token} 
					loadTemplates={loadTemplates} /> 
			)
			break;
		default:
			return ( 
				<TemplateList 
					templates={templates} 
					setTemplateContent={setTemplateContent} 
					toggleView={toggleView} 
					token={config.token} 
					loadTemplates={loadTemplates} 
				/> 
			)
	}

}


const TemplateList = ({ templates, setTemplateContent, toggleView, token, loadTemplates }) => {

	const [ edit, setEdit ] = useState( false )
	const [ sending, setSending ] = useState( false )
	
	const handleDelete = ( template, event ) => {
		
		event.preventDefault();

		if( confirm( 'Are you sure you want to remove this template?' ) ) {

			setSending(true)
			
			axios.delete( path + '/' + template.id, {
				data: {
					authenticity_token: token
				}
			}).then( response => {
				loadTemplates();
				setSending(false)
			}).catch(err => {
				setSending(false)
				console.log(err);
			});
		}

	}

	return (
		<div className="template-modal-container">
			<div className="template-modal-header">
				<h4>Choose a message</h4>
			</div>
			<div className="template-modal">
				<div className="template-list">
					{templates.map( template =>
						<div className="template" key={template.id}>
							<a onClick={(event) => setTemplateContent( template.body, event )}>
								<h5>{template.title}</h5>
								<p className="message-text">{template.body}</p>
							</a>
							{edit &&
								<div className="edit-buttons">
									<div className="grid-x grid-margin-x">
										<div className="cell small-6 medium-4 large-3">
											<a className="button plain border small expanded" onClick={(event) => toggleView( 'edit', template, event )}>Edit</a>
										</div>
										<div className="cell small-6 medium-4 large-3">
											<a className="button plain border small expanded" onClick={(event) => handleDelete( template, event )}>
												{sending ? <ButtonSpinner /> : 'Delete' }
											</a>
										</div>
									</div>
								</div>
							}
						</div>
					)}
				</div>
			</div>
			{edit ? (
				<div className="fixed-template-footer">
					<div className="button-wrapper">
						<a className="button primary" onClick={(e) => setEdit(!edit)}>Done</a>
					</div>
				</div>	
			) : (
				<div className="fixed-template-footer">	
					<div className="button-wrapper">
						<button className="button expanded plain border" onClick={(event) => toggleView( 'new', null, event )}>Save new template</button>
						<a onClick={(e) => setEdit(!edit)}>Edit your templates</a>
					</div>
				</div>
			)}
		</div>
	)
}

const NewTemplate = ({ toggleView, token, loadTemplates }) => {

	const [ title, setTitle ] = useState( '' )
	const [ body, setBody ] = useState( '' )
	const [ sending, setSending ] = useState( false )
	

	const handleSubmit = ( event ) => {
		event.preventDefault();
		
		setSending(true)
		
		axios.post( path, {
			'supplier_template': {
				'title': title,
				'body': body
			},
			authenticity_token: token
		}).then(response => {
			loadTemplates()
			setTitle('')
			setBody('')
			setSending( false )
		}).catch(err => {
			setSending( false )
			console.log( err );
		});
	}

	return (
		<div className="template-form">
			<div className="template-modal-header">
				<h4>Add new template</h4>
			</div>
			<div className="template-body">
				<label>Message title</label>
				<input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)}></input>
				<label>Message content</label>
				<textarea name="body" onChange={(e) => setBody(e.target.value)} value={body} ></textarea>
				<div className="button-wrapper">
					<button className="button primary" onClick={handleSubmit}>
						{sending ? <ButtonSpinner /> : 'Save' }
					</button>
				</div>
			</div>
		</div>
	)
}



const EditTemplate = ({ template, toggleView, token, loadTemplates }) => {

	const [ title, setTitle ] = useState( template.title )
	const [ body, setBody ] = useState( template.body )
	const [ sending, setSending ] = useState( false )


	const handleSubmit = ( event ) => {
		event.preventDefault();
		axios.put( path + '/' + template.id, {
			'supplier_template': {
				'title': title,
				'body': body
			},
			authenticity_token: token
		}).then(response => {
			loadTemplates()
			setTitle('')
			setBody('')
			setSending( false )
		}).catch(err => {
			setSending( false )
			console.log( err );
		});
	}

	return (
		<div className="template-edit-form">
			<div className="template-modal-header">
				<h4>Edit template below</h4>
			</div>
			<div className="template-body">
				<label>Message title</label>
				<input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)}></input>
				<label>Message content</label>
				<textarea name="body" value={body} onChange={(e) => setBody(e.target.value)}></textarea>
				<div className="button-wrapper">
					<button className="button primary" onClick={handleSubmit}>
						{sending ? <ButtonSpinner /> : 'Save' }
					</button>
				</div>
			</div>
		</div>
	)
}


export default Templates