import React from 'react'

const Selector = ({ selector }) => {

	return (
		<div className="styled-select">
			<select name="selector" value="" onChange={(e) => { window.location.href = e.target.value }}>
	      <option value=""> {selector.placeholder}</option>
				{selector.items.map((item, index) =>
					<option value={item.path} key={`selector-${index}`}>{item.name}</option>
				)}
			</select>
		</div>
	)
}


export default Selector
