import React, { useState } from "react"

const System = () => {

	return (
		<div>
			SYSTEM SECTION
		</div>
	)
}



export default System
