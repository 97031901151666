/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from '@rails/ujs';
Rails.start();

//import jQuery from 'jquery'
//window.$ = jQuery

//import Foundation from 'foundation-sites';
//jQuery(function() {
	//jQuery(document).foundation();
//});

import 'app.scss';
import "core-js/stable";
import "regenerator-runtime/runtime";

/* images */
import 'user_placeholder.png';


/*
Import features available for every page
*/

//lazyLoading https://github.com/aFarkas/lazysizes
import 'lazysizes';


//notification alert
import Notifications from '../global/notifications'

//tooltips
import ToolTip from '../global/tooltip'


document.addEventListener('lazybeforeunveil', function(e){
	var bg = e.target.getAttribute('data-bg');
	if(bg){
			e.target.style.backgroundImage = 'url("' + bg + '")';
	}
});// Support component names relative to this directory:

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
