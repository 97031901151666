import React from 'react'

const Button = ({ openModal }) => {

	return (
		<a onClick={openModal} className="button primary with-arrow">
			Get started for free
			<span className="icon icon-up-arrow"></span>
		</a>
	)
}
export default Button