import React, {useMemo} from 'react'
import OrderSwitcher from './navigation/order'
import ViewSwitcher from './navigation/view'
import Filter from './navigation/filter'

const Navigation = ({ filters, toggleView, order, changeOrder, runFilter }) => {
		
	return (
		<div className="category-container">
			<div className="small-12 cell">
				<div className="grid-x grid-padding-x">
					<div className="small-12 medium-6 large-5 cell text-center medium-text-left category-btns">
						<div className="grid-x grid-margin-x">

						{ Object.keys(filters).map(( filterKey ) => {
							const filter = filters[filterKey];
							
							if( filter.data.length > 0 ) {
								
								const currentFilter = useMemo(() => (
									<Filter 
										key={`filter-${filterKey}`} 
										filter={filter} 
										filterKey={filterKey}
										runFilter={runFilter}
									/>
								))
								
								return currentFilter
							}
						})}

						</div>
					</div>
					
					<OrderSwitcher
						order={order}
						changeOrder={changeOrder} 
					/>
					<ViewSwitcher 
						toggleView={toggleView} 
					/>
				</div>
			</div>
		</div>
	)
}


export default Navigation
