import React, { useState, useEffect } from 'react'

import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

let sliderChanged = false

const PriceType = ({ definition, originalPriceRange, selected, setFilter }) => {
	
	const startMin = Math.trunc(originalPriceRange.min)
    const startMax = Math.trunc(originalPriceRange.max)
    
    const [ range, setRange ] = useState( [ startMin, startMax ] )
    
	useEffect(() => {
    	if( selected == null && sliderChanged ) {
    		sliderChanged = false
    		setRange( [ startMin, startMax ] );
    		
    	}
	}, [selected])
	
	return (
		<div className="price-slider">
			<div>
				<p className="no-mg-b">Set price per person</p>
				<Range
					min={startMin}
					max={startMax}
					onChange={(v) => {
						setRange( v )
					}}
					onAfterChange={(v) => {
						sliderChanged = true
						setFilter( definition.ident, false, {
							min: range[0],
							max: range[1]
						})
					}}
					value={range}
					defaultValue={[ startMin, startMax ]}
				/>
               
                <div className="align-content vertical center">
                    <input type="text" className="form-input lightgrey mg-r" placeholder={'£' + range[0]} readOnly />
                
                    <input type="text" className="form-input lightgrey" placeholder={ '£' + range[1]} readOnly />
                </div>
			</div>
		</div>
	)
}
export default PriceType
