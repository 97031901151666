import React, {Component} from 'react';

const ProcessButton = props => {
	if( props.buttonType != undefined || props.buttonType == 'submit' ) {
		return (
			<button
				className={props.buttonClass}
				disabled={props.disabled}
				type="submit"
			>
			{ props.process ? <ButtonSpinner /> : props.children }
			</button>
		)
	} else {
		return (
			<button
				className={props.buttonClass}
				disabled={props.disabled}
				onClick={props.onClickHandler}
				type="button"
			>
			{ props.process ? <ButtonSpinner /> : props.children }
			</button>
		)
	}
};

const ButtonSpinner = props => (
	<div className="spinner">
		<div className="bounce1"></div>
		<div className="bounce2"></div>
		<div className="bounce3"></div>
	</div>
);
export default ProcessButton
