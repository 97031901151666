import React, {useState} from 'react';
import axios from 'axios'
import ProcessButton from 'shared/process-button'

const Reset = ({ token, setView }) => {

	const [ email, setEmail ] = useState( '' )
	const [ error, setError ] = useState( false )
	const [ submitted, setSubmitted ] = useState( false )
	const [ completed, setCompleted ] = useState( false )


	const handleSubmit = (event) =>{

		event.preventDefault();

		setError( false )
		setSubmitted( true )
		setCompleted( false )


		axios.post( '/users/reset.json', {
			'password_reset': {
				'email': email
			},
			authenticity_token: token
		}).then(response => {

			setEmail( '' )
			setCompleted( true )
			setSubmitted( false )

		}).catch(err => {
			setSubmitted( false )
			setError( err.response.data.message ? err.response.data.message : 'Sorry an error has occured' )
		});
	}


	return (

		<form onSubmit={handleSubmit} action="/users/reset.json" autoComplete="off" acceptCharset="UTF-8" method="post">

			{error
				? <div className="callout danger">
					Sorry password reset request has failed
					<br />{error}
				</div>
				: null
			}

			{completed
				? (
					<div className="callout success">
						Email sent with password reset instuctions
						<div className="account-help">
							<div className="panel-footer-inline text-center">
								<a onClick={(e) => setView('login')}>Back to login</a>
							</div>
						</div>
					</div>
				) : (
					<div>


						<p>Enter the email address associated with your account, and we’ll email you a link to reset your password.</p>

						<label>
							<input
								value={email}
								onChange={(e) =>
								setEmail(e.target.value)}
								className="input-group-field"
								placeholder="Enter your email address"
								type="email"
								name="password_reset[email]"
								id="password_reset_email"
							/>
						</label>

						<ProcessButton
							buttonType="submit"
							buttonClass="button primary expanded"
							disabled={ submitted || email.length < 5 }
							process={submitted}
						>
							<b>Submit</b>
						</ProcessButton>


					</div>
				)
			}



		</form>
	)
}
export default Reset
