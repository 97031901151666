import React from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'

class ToolTip extends React.Component {
	render() {
		return (
			<span data-tip={this.props.title}>
				{ this.props.iconClassName !== null ?
				<span className={this.props.iconClassName}></span>
				: this.props.text
				}
				<ReactTooltip scrollHide={true} />
			</span>
		)
	}
}

document.addEventListener('DOMContentLoaded', () => {

	var tooltip_list = document.querySelectorAll('.fizz_tooltip'); // returns NodeList
	var tooltipList = [...tooltip_list]; // converts NodeList to Array
	tooltipList.forEach(tooltipItem => {

	const title = tooltipItem.getAttribute('title');
	const iconClassName = tooltipItem.getAttribute('iconClassName');
	const text = tooltipItem.getAttribute('text');

		ReactDOM.render(
			<ToolTip title={title} iconClassName={iconClassName} text={text} />,
			tooltipItem,
		)
	});
})
