import React from 'react'
import Collapsible from 'react-collapsible'
import ListType from './types/list'
import PriceType from './types/price'
import TabbedListType from './types/tabbed-list'

const Wrapper = ({ definition, originalPriceRange, selected, setFilter }) => {
	
	const renderFilter = () => {

		switch( definition.type ) {
			case 'list':
				return <ListType definition={definition} selected={selected} setFilter={setFilter} />
			case 'tabbed_list':
				return <TabbedListType definition={definition} selected={selected} setFilter={setFilter} />
			case 'price':
				return <PriceType definition={definition} originalPriceRange={originalPriceRange} selected={selected} setFilter={setFilter} />
		}
	}

	return ( 
		<Collapsible trigger={definition.label} open={definition.config.open == true ? true : false}>
			{renderFilter( definition )}
		</Collapsible>
	)
}
export default Wrapper
