import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import Wrapper from './filters/wrapper'
import OrderSwitcher from './filters/order'
import ViewSwitcher from './filters/types/view'
import Collapsible from 'react-collapsible'

ReactModal.setAppElement('body');

const ModalStyles = {
  overlay: {
	  backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  content : {
	padding				  : '0rem',
	width				  : 'auto',
	maxWidth			  : '800px',
	height				  : 'auto',
	maxHeight             : '90%',
	top                   : '50%',
	left                  : '50%',
	right                 : 'auto',
	bottom                : 'auto',
	marginRight           : '-50%',
	transform             : 'translate(-50%, -50%)'
  }
};

let filtersSet = false

const Filters = ({ filterDefinitions, originalPriceRange, order, switchLayout, changeOrder, runFilters }) => {

console.log(filterDefinitions);

	const [ modal, showModal ] = useState( false )
	
	let filterTypes = {}
	{Object.keys(filterDefinitions).map(( filterKey ) => {
		const filter = filterDefinitions[filterKey]
		if( filter.default_selection !== undefined && filter.default_selection !== null ) {
			filterTypes[filterKey] = filter.default_selection
		} else {
			/*
			switch( filter.type ) {
			case 'price':
				filterTypes[filterKey] = {
					min: 0,
					max: 0
				}
				//filterTypes[filterKey] = null;
				break
			default:
				filterTypes[filterKey] = filter.config.multi ? [] : null
			}
			*/
			filterTypes[filterKey] = filter.config.multi ? [] : null
		}
	})}

	
	const [ filters, setFilters ] = useState( filterTypes )	

	const setFilter = ( ident, multi, data ) => {
		
		filtersSet = true
		
		if( multi ) {
			if (filters[ident].some(item => item.id === data.id)) {
				// selectedItem is in the selected array, remove it
				const filterItems = filters[ident].filter(i => i.id !== data.id)
				setFilters( {...filters, [ident]: filterItems } )
			} else {
				// selectedItem is not in the selected array, add it
				const  filterItems = filters[ident]
				filterItems.push( data )
				setFilters( {...filters, [ident]: filterItems } )
			}
		} else {
			setFilters( {...filters, [ident]: data } )
		}
	}

	const removeFilter = ( ident, multi, tag, item ) => {
		
		if( ident == 'price' ) {
			setFilters( {...filters, [ident]: null } )
		} else {
			if( multi ) {
				const filterItems = filters[ident].filter(i => i.id !== item.id)
				setFilters( {...filters, [ident]: filterItems } )
			} else {
				setFilters( {...filters, [ident]: null } )
			}
		}
	}

	const clearFilters = () => {
		setFilters( filterTypes )
	}
	
	useEffect(() => {
		if( filtersSet ) {
			runFilters( filters )
		}
	}, [filters])

	

	return (
		<>	
			<div className="grid-filters show-for-large">
				<h2 className="bold">Filters</h2>
				<div className="align-content vertical center wrap">
					<SelectedFilters 
						filterDefinitions={filterDefinitions}
						filters={filters} 
						removeFilter={removeFilter}
						wrapperClass="remove-filter border w-fit rounded"
					/>
					<div className="clear-all underline" onClick={clearFilters}>Clear all</div>
				</div>
				<div className="divider small"></div>
				<FilterList
					filterDefinitions={filterDefinitions}
					filters={filters}
					originalPriceRange={originalPriceRange}
					setFilter={setFilter}
					switchLayout={switchLayout}
				/>
			</div>

			<div className='grid-x grid-padding-x hide-for-large'>
				<div className="cell small-6 medium-4">
					<button
						className="button primary expanded small"
						onClick={() => showModal(true)}
					>
						<span className="icon icon-filter medium left white"></span>
						All filters
					</button>
				</div>

				<div className="small-6 medium-4 cell">
					<OrderSwitcher
						order={order}
						changeOrder={changeOrder} 
					/>
				</div>

				<div className="cell medium-12">
					<div className="category-container full-width">
						<div className="align-content vertical center wrap">
							<SelectedFilters 
								filterDefinitions={filterDefinitions}
								filters={filters} 
								removeFilter={removeFilter}
								wrapperClass="remove-filter small white border w-fit rounded"
							/>
							<div className="clear-all underline" onClick={clearFilters}>Clear all</div>
						</div>
					</div>
				</div>
			</div>
			
			<ReactModal isOpen={modal} style={ModalStyles}>
				<div className="align-content full-height relative split">
					<div className="inner-padding">
						<button 
							onClick={() => showModal(false)} 
							className="close-button" 
							aria-label="Close modal"
						>
							<span aria-hidden="true">×</span>
						</button>

						<h2 className="bold">Filters</h2>
						<div className="divider small"></div>

						<div className="grid-filters">
							<FilterList
								filterDefinitions={filterDefinitions}
								filters={filters}
								originalPriceRange={originalPriceRange}
								setFilter={setFilter}
								switchLayout={switchLayout}
							/>
						</div>
					</div>
					<div className="sticky-bar full-width bottom border-t">
						<div className="grid-x grid-padding-x">
							<div className="cell small-6">
								<div className="remove-filter border rounded align-content horizontal center" onClick={clearFilters}>
									Clear all
								</div>
							</div>
							<div className="cell small-6">
								<div className="button primary medium expanded" onClick={() => showModal(false)}>Apply filters</div>
							</div>
						</div>
					</div>
				</div>
			</ReactModal>
			
		</>
	
	)
}

const FilterList = ({ filterDefinitions, filters, originalPriceRange, sendFilter, setFilter, switchLayout }) => {
	return (
		<>
		{Object.keys(filterDefinitions).map(( filterKey ) => {
			if( filterDefinitions[filterKey].type != 'hidden' ) {
				return (
				<Wrapper 
					definition={filterDefinitions[filterKey]}
					originalPriceRange={originalPriceRange}
					selected={filters[filterKey]}
					sendFilter={sendFilter}
					setFilter={setFilter}
					key={filterKey} 
				/>
				)
			}
		})}
		<Collapsible trigger="View">
			<div className="align-content vertical center">
				<ViewSwitcher 
					switchLayout={switchLayout} 
				/>
				<p className="toggle-text mg-l">Show activity details</p>
			</div>
		</Collapsible>
		</>
	)
}

const SelectedFilters = ({ filterDefinitions, filters, removeFilter, wrapperClass }) => {

	let selectedFilterItems = []
	
	Object.keys(filterDefinitions).map(( filterKey ) => {
		
		const filter = filterDefinitions[filterKey]

		if( filter.type != 'hidden' ) {
			if( filters[filter.ident] !== undefined && filters[filter.ident] !== null ) {
				if( filter.config.multi ) {
					if( filters[filter.ident].length > 0 ) {
						filters[filter.ident].map(( item, index ) => {
							selectedFilterItems.push( 
								<SelectedFilterItem 
									item={item} 
									ident={filter.ident}
									multi={filter.config.multi}
									tag={filter.config.tag}
									noRemove={filter.config.no_remove}
									removeFilter={removeFilter} 
									wrapperClass={wrapperClass}
									key={`selected-filter-${filter.ident}-${index}`}
								/>
							)
						})
					}
				} else {
					selectedFilterItems.push(
						<SelectedFilterItem 
							item={filters[filter.ident]} 
							ident={filter.ident}
							multi={filter.config.multi}
							tag={filter.config.tag}
							noRemove={filter.config.no_remove}
							removeFilter={removeFilter} 
							wrapperClass={wrapperClass}
							key={`selected-filter-${filter.ident}`}
						/>
					)
	
				}
			}
		}
	})
	return selectedFilterItems.length > 0 ? selectedFilterItems : null	
}

const SelectedFilterItem = ({ item, ident, multi, tag, noRemove, removeFilter, wrapperClass }) => {
	return (
		<div className={wrapperClass}>
			{ident == 'price' ? `£${item.min} - £${item.max}` : item.name}
			{!noRemove ? (
			<a onClick = {() => removeFilter( ident, multi, tag, item )}
			><span className="icon icon-cross-plain small black"></span></a>
			) : null }
		</div>
	)
}	

export default Filters
