import React from 'react'
const OrderSwitcher = ({ order, changeOrder }) => {
	return (
		<div className="small-9 medium-4 large-5 cell">
			<div className="grid-x grid-padding-x">
				<div className="small-3 medium-4 text-right">
					<label htmlFor="popularity-select">Sort by:</label>
				</div>
				<div className="small-7 medium-8 cell small-text-left large-text-right">
					<select className="popularity-dropdown" id="popularity-select" name="popularity" value={order} onChange={(e) => changeOrder(e.target.value)}>
						<option value="1">Popularity</option>
						<option value="2">Price</option>
						<option value="3">Name</option>
						<option value="4">Newest</option>
					</select>
				</div>
			</div>
		</div>
	)
}
export default OrderSwitcher
