import React, { useState, useEffect } from 'react'

import axios from 'axios'

//websockets
import { createConsumer } from "@rails/actioncable";

//our components
import Form from './form'
import List from './list'

const Messages = ({ config, title }) => {
	
	let subscription = null;
	
	const [ loading, setLoading ] = useState( true )
	const [ messages, setMessages ] = useState( [] )
	const [ message, setMessage ] = useState( '' )
	const [ event, setEvent ] = useState( null )
	
	//called on load
	useEffect(() => { 
			
		//get list of messages
		axios.get( config.path + '/messages' ).then(response => {
			setMessages( response.data )
			setLoading(false)
		}).catch(err => {
			console.log(err);
		});
		
		//create subscription to actioncable
		const cable = createConsumer('/cable');
		subscription = cable.subscriptions.create(
			{
				channel: config.channel, 
				booking_id: config.booking_id 
			}, 
			{ 
				received: ( data ) => { 
					setEvent( data )
				} 
			}
		);
		
		
		return () => {
			subscription = null;	
		}

	}, [])
	
	
	
	useEffect(() => {
		if( event ) {
			if( event.bookingId == config.booking_id ) {
				switch( event.event ) {
					case 'status-change':
						if( message.length == 0 ) {
							//make sure we dont reload if user has typed a message
							window.location.replace( config.path );
						}
						break;
					case 'new-message':
						const newMessage = event.message
						newMessage.isNew = true
						setMessages( [ newMessage , ...messages] )
						break;
				}
			}
		}
    }, [ event ]);
    
    
	return ( 
		<>
			<Form 
				config={config}
				message={message}
				setMessage={setMessage}
				messages={messages}
				setMessages={setMessages}
			/>
			
			{loading && <Loader />}
			{!loading && <List messages={messages} />}
				
		</>
	)

}

const Loader = props => (
	<div className="text-center"><img src={require(`loader-blocks.gif`)}/></div>
);

export default Messages
