import React, { useState, useEffect } from 'react'


const Filter = ({ filter, filterKey, runFilter }) => {
	
	const [ status, setStatus ] = useState({
			open: false,
			active: filter.active,
			label: filter.label
	})
		
	useEffect(() => {
		document.body.addEventListener('click', handleBodyClick);
		return function cleanup() {
			document.body.removeEventListener('click', handleBodyClick);
		};
	}, [status] );
	

	const handleBodyClick = ( e ) => {

		let closeDropdown = true;

		if( e.target.dataset.ident == filterKey ) {
			if( e.target.dataset.id == 'filter-button') {
				if( filter.isActive ) {
					closeDropdown = false;
				}
			}
		}
		if( closeDropdown ) {
			setStatus( { ...status, open: false } );
		}
	}
	
	return (
		<span className="cell small-6">
			<span className="filter-item">

				<button 
					className={status.active ? 'button category-select-btn selected' : 'button category-select-btn'} 
					data-id="filter-button" 
					data-ident={filterKey} 
					type="button" 
					onClick={( e ) => {
						e.preventDefault();
						e.stopPropagation();
						setStatus({ ...status, open: true });
					}}
					>
					{status.label} { status.active ? ( <span className="remove-cross">&#x00D7;</span> ) : <span>&#x25BE;</span> }
				</button>

				<FilterPane
					filterKey={filterKey}
					open={status.open}
					linkToNewPage={filter.linkToNewPage}
					resetLink={filter.resetLink}
					data={filter.data}
					handleFilterLink={(e, id, name ) => {
						e.preventDefault();
						e.stopPropagation();
						runFilter( { key: filterKey, value: id } )
						setStatus( {...status, label: name, active: true, open: false } );
					}}
					clearFilter={(e) => {
						e.preventDefault();
						e.stopPropagation();
						runFilter( { key: filterKey, value: 0 } )
						setStatus( {...status, label: filter.label, active: filter.active, open: false } );
					}}
					runFilter={runFilter}
				/>

			</span>
		</span>
	)
}


const FilterPane = ({ filterKey, open, linkToNewPage, resetLink, data, handleFilterLink, clearFilter, runFilter }) => {

	const getSlug = ( slug ) => {
		return '/' + linkToNewPage && linkToNewPage.slug != null ||linkToNewPage.slug != undefined ? linkToNewPage.slug.replace('*', slug) : slug;
	}

	return (
		<div className={open ? 'dropdown-pane is-open has-position-bottom' : 'dropdown-pane'} id={`filter-${filterKey}`}>
			<ul>
				
				{!linkToNewPage ?
					<li key={filterKey + `-all`} onClick={(e) => clearFilter(e)}><a>View all</a></li>
					: null
				}

				{resetLink != undefined && resetLink ?
					<li key={filterKey + `-all`} onClick={(e) => clearFilter(e)}><a>View all</a></li>
					: null
				}

				{data.map((item, index) => {
					return (
						<li key={filterKey + `-` + item.id}>
							{linkToNewPage ? (
								<a 
									data-id="filter-seo-link" 
									data-ident={filterKey} 
									href={getSlug( item.slug )}
								>{item.name}</a>
							) : (
								<a 
									data-id="filter-react-link" 
									data-ident={filterKey} 
									onClick={(e) => handleFilterLink( e, item.id, item.name )}
								>{item.name}</a>
							)}
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default Filter
