import React from 'react'


const Event = ({ message }) => {
	
	return (
		<p className="message-event"><span>{message.body}</span></p>
	)
}

export default Event