import React from 'react'

const ViewSwitcher = ({ toggleView }) => {
	return (
		<div className="small-3 medium-2 cell small-text-left large-text-right view-button-container">
			<a href="#" onClick={(e) => toggleView( e,'list' )} title="view-area"><div className="view-area"><span className="icon icon-list view-box"></span></div></a>
			<a href="#" onClick={(e) => toggleView( e,'grid' )} title="view-area"><div className="view-area"><span className="icon icon-grid view-grid"></span></div></a>
		</div>
	)
}
export default ViewSwitcher

