import React, { useEffect, useState } from 'react'
const ListItem = ( { ident, item, multi, selected, setFilter, redirect } ) => {
		
	let url = null
	
	if( redirect ) {
		url = redirect.replace('*', item.slug)
	}
	
	return (
		<div className="full-width"> 
			
				{multi ? (
					<>
					<input 
						type="checkbox" 
						className="checkbox" 
						name={`filter-${ident}-${item.id}`}
						onChange={() => {
							setFilter( ident, multi, {
								id: item.id,
								name: item.name
							})
						}}
						checked={selected.some(selectedItem => selectedItem.id === item.id)}
						id={`filter-${ident}-${item.id}`}
					/>
					<label htmlFor={`filter-${ident}-${item.id}`}>{item.name}</label>
					</>
				) : (
					<>
					<input 
						type="radio" 
						name={`filter-${ident}`} 
						onChange={() => {
							if( redirect ) {
								window.location.replace(url)
							} else {
								setFilter( ident, multi, {
									id: item.id,
									name: item.name
								})
							}
						}}
						id={`filter-${ident}-${item.id}`}
						checked={selected !== null && selected.id == item.id}
					/>
					{redirect ? (
						<label htmlFor={`filter-${ident}-${item.id}`}><a href={url} className="link black">{item.name}</a></label>	
					) : (
					<label htmlFor={`filter-${ident}-${item.id}`}>{item.name}</label>
					)}
					</>
				)}
			
		</div>
	)
}
export default ListItem