import React from 'react'
import { CSSTransition } from 'react-transition-group';

const Message = props => {
	
	const componentClasses = ['message clearfix'];
	componentClasses.push( props.message.source == 2 ? 'message-right' : 'message-left' );
	componentClasses.push( typeof props.message.isNew != "undefined" && props.message.isNew ? 'message-new' : 'message-visible' );
	
	return (
		 <CSSTransition {...props} classNames="message-fade" timeout={{ enter: 700, exit: 700 }}>
			<div className={componentClasses.join(' ')}>
				
				{props.message.source == 1 &&
					<div className="avatar">
						<div className="image" style={{backgroundImage: `url("${props.message.avatar}")`}}>&nbsp;</div>
					</div>
				}
				<div className="body">
					<span className="message-text">{props.message.body}</span>
					{props.message.attachments &&
						<>
						{props.message.attachments.map((attachment, index) =>
							<div className="message-attachment" key={`attachment-${props.message.id}-${index}`}>
								<img src={require(`download-file.svg`)}/>
								<a href={`/uploads/${attachment.id}/${attachment.key}`} target="_blank">Attachment - {attachment.name}</a>
							</div>
						)}
						</>
					}
					<div className="meta">{props.message.time}</div>
				</div>
				
				{props.message.source == 2 || props.message.internal ? (
					<div className="avatar">
						{ props.message.internal ? (
							<div className="fizzbox-avatar"></div>
						) : (
							<div className="image" style={{backgroundImage: `url("${props.message.avatar}")`}}>&nbsp;</div>
						)}
					</div>
				):null}
			</div>
		</CSSTransition>
	)
}

export default Message