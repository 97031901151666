import React from 'react'


const Notification = ({ message }) => {
	
	let text = null;

	switch( message['category'] ) {

		case 'departs_today':
			text = 'As your booking is today, please call <b>' + message.body['supplier'] + '</b> on <a href="tel:' + message.body['phone'] + '"><b>' + message.body['phone'] + '</b></a> as there is a chance they may not respond to this message in time.';
			break;
		default:
			const whenOpen = message.body['today'] ? '<b>Today</b>' : 'again on <b>' + message.body['name'] + '</b>';
			text = 'Hi! Just to let you know, <b>' + message.body['supplier'] + "'s</b> offices are now closed. They open " + whenOpen + ' from <b>' + message.body['open'] + '</b> to <b>' + message.body['closed'] + '</b>. They may respond to messages outside these hours.';
	}

	return (
		<div className="message clearfix message-right">
			<div className="body" dangerouslySetInnerHTML={{ __html: text }}></div>
			<div className="avatar">
				<div className="fizzbox-avatar"></div>
			</div>
		</div>
	)
}

export default Notification