import React, { useState } from "react"

const Finance = () => {

	return (
		<div>
			FINANCE SECTION
		</div>
	)
}



export default Finance
