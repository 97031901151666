import React, { useState } from "react"

const Crm = () => {

	return (
		<div>
			CRM SECTION
		</div>
	)
}



export default Crm
