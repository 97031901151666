import React, {useState} from 'react';

/*
FIZZBOX AUTHENICATION HANDLER
*/

//require screens
import Login from './login'
import Reset from './reset'
import Signup from './signup'

const Account = ({ defaultView, token, fields, path, back, allowLogin, allowSignup, success }) => {
	
	const [ view, setView ] = useState( defaultView != undefined ? defaultView : 'login' )
	
	//hold a global setting for out GDPR stuff
	const [ optoutMarketing, setOptoutMarketing ] = useState( true )
	
	const facebookLogin = ( params ) => {
		const width = 400;
		const height = 600;
		const left = (screen.width/2)-(width/2);
		const top = (screen.height/2)-(height/2);
		const externalWindow = window.open( "/facebook-login?" + redirectUrl( params ), 'oauthFB', "menubar=no,status=no,width=" + width + ",height=" + height + ",toolbar=no,left=" + left + ",top=" + top );
	}
	
	const redirectUrl = ( params ) => {
		const urlParams = [];
		urlParams.push( 'redirect[path]=' + path );
		Object.keys( params ).map(function(key) {
			urlParams.push( 'redirect[' + encodeURI( key ) + ']=' + encodeURI( params[key] ) );
		});
		return urlParams.join('&');
	}
	
	return (
		<div className="auth-container">

			{back ? (
			<a onClick={back} className="backBtn button primary">
				<span className="icon icon-left"></span> Back
			</a>
			) : null }

			{ view == 'signup' ? (
				<Signup 
					token={token}
					fields={fields}
					setView={setView}
					optoutMarketing={optoutMarketing}
					setOptoutMarketing={setOptoutMarketing}
					facebookLogin={facebookLogin}
					allowLogin={allowLogin}
					success={success}
			/>
			) : view == 'reset' ? (
				<Reset
					token={token}
					setView={setView}
				/>
			) :  (
				<Login
					token={token}
					fields={fields}
					setView={setView}
					facebookLogin={facebookLogin}
					back={back}
					allowSignup={allowSignup}
					success={success}
				/>
			)}

		</div>
	)
}

export default Account;