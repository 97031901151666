import React, { useState, useEffect } from 'react'

const StateContext = React.createContext()

function stateReducer( state, action ) {
	
	switch (action.type) {

		case 'ERROR': {
				return {...state, error: action.error, modal: action.modal }
		}
		
		case 'RESET_ERROR': {
			return {...state, error: false }
		}

		case 'MODAL': {
			return { ...state, modal: action.modal }
		}

		default: {
			throw new Error(`Unsupported action type: ${action.type}`)
		}
	
	}

}


function StateProvider(props) {
	
	const [state, dispatch] = React.useReducer(stateReducer, {
		error: false,
		modal: false
	});
	const value = React.useMemo(() => [state, dispatch], [state])
	
	return (
		<StateContext.Provider value={value} {...props} />
	)
  	
}

const modalParams = ( type, title, payload ) => {
	return {
		type: type,
		title: title,
		payload: payload
	}
}

const useGlobalState = () => {
	
	const context = React.useContext(StateContext)
	
	if (!context) {
		throw new Error(`useGlobalState must be used within a useGlobalStateProvider`)
	}

	const [state, dispatch] = context
	
	//handle an error form a API request
	const handleError = ( error ) => {
		console.log(error)
		dispatch({ 
			type: 'ERROR', 
			error: error
		});	
	}

	//raise an error from the application
	const raiseError = ( error, detail ) => {
		dispatch({ type: 'ERROR', error: {title: error, detail: detail} });	
	}
	
	//clear the error
	const clearError = () => {
		dispatch({ type: 'ERROR', error: false });	
	}

	const hasFieldMessage = ( field ) => {
		if( state.error && state.error.fields[field] != undefined ) {
			console.log( state.error.fields[field] )
			return <div className="input_error_message">{state.error.fields[field]}</div>
		}
	}

	const renderModal = ( type, title, payload ) => {
		dispatch({ 
			type: 'MODAL',
			modal: modalParams( type, title, payload )
		});
	}
	
	const closeModal = () => {
		dispatch({ 
			type: 'MODAL',
			modal: false
		});
	}

  
	return {
		state,
		dispatch,
		handleError,
		raiseError,
		clearError,
		hasFieldMessage,
		renderModal,
		closeModal
	}
}


export { StateProvider, useGlobalState }