import React, {useState } from 'react'

const ViewSwitcher = ({ switchLayout }) => {	

	const [active, setActiveState] = useState(false);
	const toggle = (e) => { 
		setActiveState(active => !active);	
		active ? switchLayout( e,'grid' ) : switchLayout( e,'list' );
	}

	return (
	<button className={active ? 'toggle-btn on' : 'toggle-btn off'} onClick={toggle}>
		<span className="pin" />
	</button>
	)
}

  export default ViewSwitcher