import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { createConsumer } from "@rails/actioncable";

let cable = createConsumer('/cable');
let notificationSubscription = null;

class Notifications extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			messages: this.props.messageCount,
			update: false
		};
	}

	componentDidMount = () => {
		const channel = this.props.type == 2 ? 'SupplierNotificationsChannel' : 'UserNotificationsChannel';
		notificationSubscription = cable.subscriptions.create( { channel: channel }, { received: this.respondToStatusChange } )
	}

	componentWillUnmount = () => {
		notificationSubscription = null;
	}

	respondToStatusChange = ( data ) => {
		switch( data.event ) {
			case 'new-message':
			case 'new-enquiry':
				this.setState({
					messages: data.messageCount,
					update: true
				});
				this.countdown = setInterval(this.removeAnimation, 500);
				break;
		}
	}

	removeAnimation = () => {
		clearInterval(this.countdown);
		this.setState({
			update: false
		});
	}

	render() {
		if( this.state.messages > 0 ) {

			const iconClass =  this.state.update ? 'menu__item--message-count vertical-holder message-animate' : 'menu__item--message-count vertical-holder';

			return (
				<a className="menu__item--link" href={this.props.path}>
					<span className={iconClass}>
						<div className="vertical-item">{this.state.messages}</div>
					</span>
				</a>
			)
		} else {
			return( null )
		}

	}
}

document.addEventListener('DOMContentLoaded', () => {
	const notifications = document.querySelector('#cpt-notifications');
	if( notifications != null ) {
		const messageCount = Number( notifications.getAttribute('messageCount') );
		const path = notifications.getAttribute('path');
		const type = Number( notifications.getAttribute('type') );
		ReactDOM.render( <Notifications messageCount={messageCount} path={path} type={type} />, notifications )
	}
})
