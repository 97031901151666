import React, { useState } from 'react';
import axios from 'axios'
import ProcessButton from 'shared/process-button'

const Login = ({ token, fields, setView, facebookLogin, allowSignup, success }) => {

	const [ formData, setFormData ] = useState({
		email: '',
		password: '',
		remember: false
	})

	const [ submitted, setSubmitted ] = useState( false )
	const [ error, setError ] = useState( false )
	const [ showResetPassword, setShowResetPassword ] = useState( false )
	const [ attempts, setAttempts ] = useState( 0 )

	const isButtonActive = () => {
		return formData.email.length > 0 && formData.password.length > 0
	}

	const handleSubmit = (event) =>{
		event.preventDefault();

		setError( false )
		setSubmitted( true )
		setAttempts( attempts +1 )

		axios.post( '/login.json', {
			authenticity_token: token,
			fields: fields,
			'session': formData
		}).then(response => {
			if( response.data.status ) {
				success( response.data.user );
			} else {
				setError( true )
				setSubmitted( false )
			}
		}).catch(err => {
			setError( true )
			setSubmitted( false )
		});

	}

	return (
		<form onSubmit={handleSubmit} className="auth-login" action="/login" autoComplete="on" acceptCharset="UTF-8" method="post">

			{error &&
				<div className="callout danger">Login has failed</div>
			}


			{attempts > 2 &&
				<div className="password-reset">
					<div className="button large">
						<a onClick={ (e) => setView('reset')}>Reset your password</a>
					</div>
				</div>
			}


			<label className="auth-login--label">
				<span className="form-label">Email:</span>
				<input
					value={formData.email}
					onChange={ e => setFormData({...formData, email: e.target.value })}
					className="form-margin"
					placeholder="Your email address"
					type="email"
					name="email"
				/>
			</label>

			<label className="auth-login--label">
				<span className="form-label">Password</span>
				<div className="account-help">
					<a onClick={(e) => setView( 'reset' )}>Forgot your password?</a>
				</div>
				<input
					value={formData.password}
					onChange={ e => setFormData({...formData, password: e.target.value })}
					className="form-margin"
					placeholder="Your password"
					type="password"
					name="password"
				/>
			</label>

			<div className="grid-x grid-margin-x">
				<div className="cell small-12 medium-6 text-left">
					<div className="checkbox-container single">
						<div className="checkbox">
							<label htmlFor="remember">
								<input
									checked={formData.remember}
									onChange={ e => setFormData({...formData, remember: e.target.checked ? 1 : 0 })}
									type="checkbox"
									value="1"
									name="remember"
								/>
								<span>Remember me</span>
							</label>
						</div>
					</div>
				</div>
			</div>

			<ProcessButton
				buttonType="submit"
				buttonClass="button primary expanded"
				disabled={!isButtonActive()}
				process={submitted}
			>
			<b>Log in</b>
			</ProcessButton>

			<div className="fb-login">
				<p className="or-divider"><span>or</span></p>
				<a className="button"
					onClick={ (e) => {
						e.preventDefault();
						facebookLogin( { auth: 1 } )
					}}>

					<b>Log in with Facebook</b>
				</a>
			</div>

			{allowSignup && <div className="text-center">Don't have an account? <a onClick={() => setView('signup')}>Sign up now!</a></div>}

	</form>
	)
}

export default Login
