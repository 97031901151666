import React, { useState, useEffect, useRef, useMemo } from "react"
import List from './list'
import Navigation from './navigation'
import Selector from './selector'
import HeroFilter from "./hero_filter"
import axios from 'axios'
import 'loader-blocks.gif';

const Grid = ({ config, data }) => {

	const [ view, setView ] = useState('grid')
	const [ type, setType ] = useState( data.type )
	const [ loading, setLoading ] = useState( false )
	const [ search, setSearch ] = useState( config.search )
	const [ items, setItems ] = useState( data.items )
	
	
	const mounted = React.useRef(false);
	
	useEffect(() => {

		if( mounted.current ) {
			const unmount = loadData();
			return () => unmount && unmount();
		} else {
			mounted.current = true;
		}
		
	}, [search]);

	const changeOrder = ( order ) => {
		if( order !== parseInt( search.order )) {
			setSearch( { ...search, order: order } )
		}
	}
	
	const runFilter = ( filter ) => {
		setSearch({ ...search, filters: {...search.filters, [filter.key]: filter.value }})
	}

	const toggleView = ( e, view ) => {
		e.preventDefault();
		e.stopPropagation();
		setView(view);
	}
	
	
	const loadData = () => {
		setLoading( true );
		axios.post( '/products/list', {
			authenticity_token: config.token,
			search: search
		}).then( response => {
			setLoading( false );
			setItems( response.data.items );

		}).catch(err => {
			setLoading( false );
			console.log(err);
		});
	}
	
    return (
      <React.Fragment>

		{config.selector &&
			<Selector selector={config.selector} />
		}

		{config.heroFilter && <HeroFilter config={config.heroFilter} filters={search.filters} runFilter={runFilter} /> }
		
		<Navigation
			filters={config.filters}
			toggleView={toggleView}
			order={search.order}
			changeOrder={changeOrder}
			runFilter={runFilter}
		/>


		{loading &&
			<div className="loader small-12 cell text-center">
				<img src={require(`loader-blocks.gif`)}/>
			</div>
		}
		
		{!loading &&
		<List 
			view={view}
			config={config}
			items={items} 
		/>
		}

	  </React.Fragment>
    );
}

const Loader = props => (
	<div className="loader small-12 cell text-center">
		<img src={require(`loader-blocks.gif`)}/>
	</div>
);

export default Grid
