import React, { useState, useRef } from 'react'
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js';
import ProcessButton from '../../process-button'

//add this to fix ie11 fetch problems
import "isomorphic-fetch"

//our components
import CardForm from './form'

const errorColor = 'red';
const validColor = '#cacaca';

const CardPayment = ({ token, auth, config, setError, additionalFormData, getPaymentData }) => {
	
	const stripe = useStripe();
	const elements = useElements();
	const [submitted, setSubmitted] = useState(false);

	//initiate a state variable to handle the validation
	const initialFormData = {	
		cardFirstname: {
			valid: auth.firstname !== undefined && auth.firstname.length > 0 ? true : false,
			name: "Card Holder First Name",
			value: auth.firstname !== undefined ? auth.firstname : '',
			display: true
		},
		cardLastname: {
			valid: auth.lastname !== undefined && auth.lastname.length > 0 ? true : false,
			name: "Card Holder Last Name",
			value: auth.lastname !== undefined ? auth.lastname : '',
			display: true
		},
		cardPostcode: {
			valid: auth.postcode !== undefined && auth.postcode != null && auth.postcode.length > 0 ? true : false,
			name: "Card Holder Postcode",
			value: auth.postcode !== undefined && auth.postcode != null && auth.postcode.length > 0 ? auth.postcode : '',
			display: true
		},
		cardNumber: {
			valid: false,
			name: "Card Number",
			display: true,
			id: 'cardNumber-wrapper'
		},
		cardExpiry: {
			valid: false,
			name: "Card Expiry",
			display: true,
			id: 'cardExpiry-wrapper'
		},
		cardCvc: {
			valid: false,
			name: "Card CVC",
			display: true,
			id: 'cardCvc-wrapper',
		},
		terms: {
			valid: config.agreement != undefined && config.agreement == false ? true : false,
			name: "Agree to our terms and conditions",
			display: false
		}
	};
	
	//merge any new validations
	const [formData, setFormData] = useState( initialFormData );

	const handleSubmit = async (event) => {
		
		event.preventDefault();

		setError( false );
		setSubmitted( true );

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			setSubmitted( false );
			return;
		}
		
		
		//const childFieldErrors = validatePaymentData();
		//const cardFieldErrors = checkFields();

		//const fieldErrors =[...childFieldErrors, ...cardFieldErrors];
		//merge in the errors from any 
		
		const parentFormData = await additionalFormData()
		const fieldErrors =  checkFields( {...formData, ...parentFormData } )
		
		if( fieldErrors.length > 0 ) {
			setError( 'Please correct the following fields: ' + fieldErrors.join(', ') );
			setSubmitted( false );
			return false;
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardNumberElement),
			billing_details: {
				// Include any additional collected billing details.
				name: formData.cardFirstname.value + ' ' + formData.cardLastname.value,
				address: {
					postal_code: formData.cardPostcode.value
				}
			},
		});

		stripePaymentMethodHandler(result);

	}

	const genPostParams = ( field, id ) => {

		const paymentData = getPaymentData( formData )

		paymentData[field] = id;
		
		return {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				payment: paymentData,
				authenticity_token: token
			})
		}
	}

	const stripePaymentMethodHandler = async (result) => {

		if (result.error) {
			if( typeof result.error === 'object' && result.error !== null ) {
				setError( result.error.message );
			} else {
				setError( result.error );
			}
			setSubmitted( false );
			// Show error in payment form
		} else {
			// Otherwise send paymentMethod.id to your server (see Step 4)
			const res = await fetch( config.paths.post, genPostParams( 'payment_method_id', result.paymentMethod.id ) );
			try {
				const paymentResponse = await res.json();

				// Handle server response (see Step 4)
				handleServerResponse(paymentResponse);
			}
			catch( err ) {
				setSubmitted( false );
				setError( err.message );
			}
		}
	}


	const handleServerResponse = async (response) => {

		if ( response ) {
			//check response is not empty
			if( Object.keys(response).length === 0 && response.constructor === Object ) {
				handleResponseError('Empty response object')
			} else {

				if (response.error) {
					// Show error from server on payment form
					if( typeof response.error === 'object' && response.error !== null ) {
						handleResponseError( response.error.message );
					} else {
						handleResponseError( response.error );
					}

				} else if (response.requires_action) {
					
					// Use Stripe.js to handle the required card action
					const { error: errorAction, paymentIntent } = await stripe.handleCardAction(response.data.payment_intent_client_secret);

					if (errorAction) {
						// Show error from Stripe.js in payment form
						handleResponseError( errorAction.message );
					} else {
						// The card action has been handled
						// The PaymentIntent can be confirmed again on the server
						const serverResponse = await fetch( config.paths.post, genPostParams( 'payment_intent_id', paymentIntent.id ) );
						handleServerResponse(await serverResponse.json());
					}
				} else {
					// Show success message
					window.location.replace( config.paths.success );
				}
			}
		} else {
			handleResponseError( 'Invalid response object' )
		}
	}

	const handleResponseError = ( error ) => {
		setError( error );
		setSubmitted( false );
	}


	const checkFields = ( fields ) => {
		
		const fieldErrors = [];

		Object.keys( fields ).map(( key ) => { 
			const field = fields[key];

			if( !field.valid ) {
				fieldErrors.push( field.name );
			}
			if( field.display ) {
				const id = field.id !== undefined ? field.id : key
				const element = document.getElementById( id )
				if (element != null ) {
					element.style.borderColor = field.valid ? validColor : errorColor;
				}


				
			}
		});
		return fieldErrors;
	}

	return (
		<>
			<CardForm 
				formData={formData} 
				handleFormChange={(e) => {
					const newFormData = formData;
					newFormData[e.target.name].value = e.target.value
					newFormData[e.target.name].valid = e.target.value.length > 0 ? true : false;
					setFormData( newFormData );
				}}
				handleCardChange={(e) => {
					const newFormData = formData;
					newFormData[e.elementType].valid = e.complete && !e.empty ? true : false;
					setFormData( newFormData );
				}} 
			/>

			<ProcessButton
				buttonClass="button primary expanded"
				onClickHandler={handleSubmit}
				process={submitted}
			>
				{config.buttonText}
			</ProcessButton>

		</>
	)
}

export default CardPayment