import React from "react";
import NumberFormat from "react-number-format";

const Stars = ({ rating }) => (
	<span>
		{[...Array(5)].map(function(e, i) {
			return (
				<span key={i} className={i + 1 <= rating ? "icon icon-star-full" : "icon icon-star"} />
			)
		})}
	</span>
);


const Product = ({ product, cdnPath, path, promoted }) => {
	const isPromoted = promoted && product.promoted ? true : false
	const wrapperClass = isPromoted ? "product-grid__product--link promoted" : "product-grid__product--link"
	const hasLocation = product.location.length > 0 && !product.virtual ? true : false
	const title = hasLocation ? `${product.name} in ${product.location}` : product.name
	const hasPrice = product.price != null && product.price ? true : false
	const hasCapacity = product.capacity || product.minimum ? true : false
	const productPath = path + "/" + product.slug

	const capacity = () => {
		if( product.capacity == product.minimum ) {
			return <p>{product.capacity}</p>
		} else if( product.minimum > 0 && product.capacity == 0) {
			return <p>{product.minimum} + </p>
		} else if( product.capacity > 0  ) {
			return  <p>{product.minimum}{" "}-{" "}{product.capacity}</p>
		} else {
			return null
		}
	}

	const price = () => {
		return(
			<>
			{ product.price_from && <p>From{" "}</p>}
			<NumberFormat
				value={product.price}
				displayType={"text"}
				thousandSeparator={true}
				prefix={"£"}
				decimalScale={2}
				fixedDecimalScale={true}
			/>{" "}
			<p>{product.fixed_price ? "Per group" : "per person"}</p>
		  </>
		)
	}

	return (
		<div className="cell product-grid__product">

			<a className={wrapperClass} href={productPath}>

				<div className="product-grid__product--image-wrapper" name={product.name}>

					<div className="image-container">
						<div className={`product-grid__product--image lazyload${product.sold_out ? ' sold-out' : ''}`} data-bg={`${cdnPath}/${product.image}?w=400&h=300&fit=crop&auto=compress`}>
							<div className="product-grid__product--image-overlay"></div>
						</div>
					
						{product.badge && <div className={`badges badge-` + product.badge} ></div>}

					</div>

					<div className="product-grid__product--name">
						<div>
							{isPromoted && <div className="sponsored"><p>Sponsored</p></div>}

							<span className="product-grid__product--heading">
								<span>{title}</span>
							</span>

							{product.sold_out &&
								<p className="mg-v-sm">
									<span className="product-grid__product--banner sold-out">2024 DATES COMING SOON</span>
								</p>
							}

							<div className="align-content split">
								{product.reviews > 0 && (
								<p className="product-grid__product--reviews">
									<Stars rating={product.rating} />{" "}<span>({product.reviews})</span>
								</p>
								)}
							</div>

							<p className="product-grid__product--supplier">
								{!product.sold_out && product.supplier.name}
							</p>
						</div>

						<div className={hasCapacity ? "align-content vertical bottom horizontal split" : "align-content vertical bottom horizontal right"}>
							
							{hasCapacity && (
								<div className="align-content vertical center">
									<span className="icon icon-group mg-r-sm"></span>
									{capacity()}
								</div>
							)}
							
							{hasPrice && (			
								<span className="product-grid__product--price">{price()}</span>			
							)}
						</div>
					</div>

					<div className="product-grid__product--summary">
						<span>{product.summary}</span>
					</div>

					<div className="view-btn">
						<button className="button primary small" href={productPath}>View</button>
					</div>

				</div>
			</a>
		</div>
	)
}

export default Product