import React, { useState, useEffect } from "react"
import { useGlobalState } from 'shared/context/global-context'
import { Router, Link } from "@reach/router"

//layout
import Navigation from './navigation'

//sections
import Crm from './sections/crm'
import Products from './sections/products'
import Finance from './sections/finance'
import System from './sections/system'


const App = () => {

	return (
		<div>
			<Router basepath="/administration">
				<Crm path="/" />
				<Products path="products" />
				<Finance path="finance" />
				<System path="system" />
			</Router>
		</div>
	)
}



export default App
