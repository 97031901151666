import React from 'react'
import { TransitionGroup } from 'react-transition-group';

//different type of mesages
import Message from './messages/message'
import Event from './messages/event'
import Notification from './messages/notification'

const List = ({messages}) => {
	
	//const [ message, setMessage ] = useState( '' )
	
	
	return (
		<div id="message-list">
			<TransitionGroup>
				{messages.map(function(message, index) {
					if( message.type == 'message' ) {
						return 	<Message message={message} key={`message-` + message.id} />
					} else if( message.type == 'notification' ) {
						return 	<Notification message={message} key={`notification-` + index} />
					} else {
						return 	<Event message={message} key={`event-` + message.id} />
					}
				})}
			</TransitionGroup>
		</div>
	)
}

export default List