import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios'

import ButtonSpinner from '../../../shared/button-spinner'
import Templates from './templates'
import TermsWarningPopUp from './terms_warning_pop_up'

const Form = ({ config, message, setMessage, messages, setMessages }) => {

	const [ sending, setSending ] = useState( false )
	const [ file, setFile ] = useState( false )
	
	const [ showModal, setShowModal ] = useState( false )
	const [ name, setName ] = useState( '' )
	const [ is_supplier, setIsSupplier ] = useState( false )

	const [ violation, setViolation ] = useState( false )
	
	const inputRef = useRef()

	const handleSubmit = ( e, sendAnyway ) => {

		e.preventDefault();

		setSending( true )

		const formData = new FormData();
		formData.append( 'authenticity_token', config.token );
		formData.append( 'message[body]', message );
		formData.append( 'message[send_anyway]', sendAnyway );
	
		if( file ) {
			formData.append( 'message[file]', file );
		}

		const url = config.path + '/messages';

		axios.post( url, formData ).then(response => {

			const newMessages = messages;

			response.data.map(function( newMessage, index ) {
				newMessage.isNew = true;
				newMessages.unshift( newMessage );
			});

			setMessages( newMessages )
			setMessage('')
			setFile('')
			setSending( false )

		}).catch(err => {
			setSending( false )
			if(err.response.data.type == 'violation') {
				setViolation({
					supplier: err.response.data.errors.is_supplier,
					name: err.response.data.errors.name,
					violations: err.response.data.errors.violations
				})
			}
		});
	}

	return (
		<div className="panel">
			<h3 dangerouslySetInnerHTML={{__html: config.title}} />
			<a name="message"></a>
			<form className="new_message" id="new_message">
				<div className="message message-visible clearfix">
					<div className="body body-with-footer">
						<div className="wrapper template">
							{config.supplier && <Templates config={config} setMessage={setMessage} /> }
							<textarea rows="4" placeholder="your message" name="message[body]" id="message_body" value={message} onChange={(e) => setMessage( e.target.value )} ></textarea>
						</div>
						<div className="message-footer">
							<div className="grid-x grid-margin-x">
								<div className="cell medium-8">
									<input
									  ref={inputRef}
									  onChange={(e) => setFile( e.target.files[0] )}
									  type="file"
									  style={{ display: "none" }}
									  // multiple={false}
									/>
									
									<TermsWarningPopUp 
										violation={violation} 
										setViolation={setViolation}
										handleSubmit={handleSubmit}
									/>
									
									{!file &&
									<button className="button white" type="button" onClick={() => inputRef.current.click()}>
										<span className="icon icon-upload"></span>Add attachment
									</button>
									}
									{ file &&
										<div className="attached-file">{file.name} <a onClick={(e) => setFile(false)}><img src='/images/static-pages/guides/cross.png' width='12' height='12' alt='cross icon' /></a></div>
									}
								</div>
								<div className="cell medium-4">
									<button name="commit" className="button primary small" disabled={sending || message.length < 2 }  onClick={(e) => handleSubmit( e, false )}>
										{sending ? <ButtonSpinner /> : 'Send' }
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Form
