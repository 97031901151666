import React, { useState, useEffect, useRef, useMemo } from "react"
import List from './list'
import Filters from './filters'
import Selector from './selector'
import Advert from 'shared/advert.jsx'
import axios from 'axios'
import 'loader-blocks.gif';

const Grid = ({ config, filters, results }) => {
	
	const [ layout, setLayout ] = useState('grid')
	const [ type, setType ] = useState( config.type )
	const [ loading, setLoading ] = useState( false )
	const [ searchResults, setSearchResults ] = useState( results )
	
	let originalPriceRange = false
	if( results.price != undefined ) {
		originalPriceRange = results.price
	}
	
	const [ payload, setPayload ] = useState({
		 type: config.type,
		 path: config.path,
		 order: 1,
		 filters: {}
	})
		
	const mounted = React.useRef(false);
		
	useEffect(() => {

		if( mounted.current ) {
			const unmount = loadData();
			return () => unmount && unmount();
		} else {
			mounted.current = true;
		}
		
	}, [payload]);

	const changeOrder = ( order ) => {
		if( order !== parseInt( search.order )) {
			setPayload( { ...payload, order: order } )
		}
	}
	
	const runFilters = ( sentFilters ) => {
		
		console.log( 'RUN FILTERS' );
		
		const filterValues = {}
		const tags = []

		Object.keys(sentFilters).forEach( filter => {
				
			const definition = filters[filter]
			
			if( definition.ident == 'price' ) {
				filterValues[definition.ident] = sentFilters[filter] !== undefined ? sentFilters[filter] : null
			} else {
				 if( definition.config.multi ) {
				 	 const values = []
				 	 if( sentFilters[filter] !== undefined || sentFilters[filter] !== null ) {
				 	 	 sentFilters[filter].forEach( item => {
				 	 	 	if( !item.id !== undefined ) {
				 	 	 		values.push( item.id )
				 	 	 	}
				 	 	 })
				 	 	 filterValues[definition.ident] = values.length > 0 ? values : null
				 	 } else {
				 	 	 filterValues[definition.ident] = null
				 	 }
				 } else {
				 	 filterValues[definition.ident] = sentFilters[filter] !== null && sentFilters[filter].id !== undefined  ? sentFilters[filter].id : null
				 }
			}
			
			if( definition.config.tag && filterValues[definition.ident] !== null ) {
				if( definition.config.multi ) {
					//TODO: multi definition for tags
				} else {
					tags.push(filterValues[definition.ident])
				}
			}
			
		})
		
		//if( tags.length ) {
		filterValues['tags'] = tags

		setPayload({ ...payload, filters: filterValues })
	}

	const switchLayout = ( e, layout ) => {
		e.preventDefault();
		e.stopPropagation();
		setLayout(layout);
	}
	
	const loadData = () => {
		setLoading( true );
		
		axios.post( '/products/list2', {
			authenticity_token: config.token,
			search: payload
		}).then( response => {
			setLoading( false );
			setSearchResults( response.data );

		}).catch(err => {
			setLoading( false );
			console.log(err);
		});
	}
	
    return (
      <React.Fragment>

		{config.selector &&
			<Selector selector={config.selector} />
		}

		<div className="grid-x grid-padding-x">
			
			<div className="cell small-12 large-3">
				
			
				<Filters 
					filterDefinitions={filters}
					originalPriceRange={originalPriceRange}
					switchLayout={switchLayout}
					order={searchResults.order}
					changeOrder={changeOrder}
					runFilters={runFilters}
				/>

				{config.advert ? (
					<div className="show-for-large mg-v-lrg">
						<Advert />
					</div>
				) : null}
				
			</div>

			<div className="cell small-12 large-9">
				<>
				{loading &&
					<div className="loader small-12 cell text-center">
						<img src={require(`loader-blocks.gif`)}/>
					</div>
				}
				{!loading &&
				<List 
					layout={layout}
					config={config}
					results={searchResults}
					order={searchResults.order}
					changeOrder={changeOrder}
				/>
				}
				</>
			</div>
		</div>
		

	  </React.Fragment>
    );
}

const Loader = props => (
	<div className="loader small-12 cell text-center">
		<img src={require(`loader-blocks.gif`)}/>
	</div>
);

export default Grid
