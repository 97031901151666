import React, { useEffect, useState } from 'react'
import Search from './shared/search'

import ListTab from './list/tab'

const ListType = ({ definition, selected, setFilter, sendFilter }) => {
	
	return (
		
		<ListTab
			listData={definition.data}
			truncate={definition.config.truncate}
			search={definition.config.search}
			multi={definition.config.multi}
			ident={definition.ident}
			selected={selected} 
			setFilter={setFilter} 
			redirect={definition.config.redirect}
		/>
		
	)
}

export default ListType
